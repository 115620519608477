import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import './App.css';

import { StateMachineProvider } from "little-state-machine";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Login from './login';
import Dashboard from './dashboard';
import Appointments from './appointments';
import PatientDetails from './details';
import Register from './register';
import Book from './book';
import Logout from './logout';

import { registerLicense } from '@syncfusion/ej2-base';
import { useEffect } from "react";
//registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VjXH5ccnJRRGJcV0I='); // old
registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0dhWH9YdHdQRmhZUUI=');

function App() {

    useEffect(() => {
        document.title = `${process.env.REACT_APP_PRACTICE} Patient Portal`
     }, []);

    return (

        <StateMachineProvider>
            <Router basename={process.env.REACT_APP_PATH}>
                <Routes>
                    <Route path="/" element={<Login />}/>
                    <Route path="/home" element={<Dashboard />}/>
                    <Route path="/appointments" element={<Appointments />}/>
                    <Route path="/book" element={<Book />}/>
                    <Route path="/details" element={<PatientDetails />}/>
                    <Route path="/register" element={<Register />}/>
                    <Route path="/logout" element={<Logout />}/>
                </Routes>
            </Router>
        </StateMachineProvider>
    );
}

export default App;

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Layout from "./layout";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useForm } from "react-hook-form";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useStateMachine } from "little-state-machine";

export default function PatientDetails(props) {

    let navigate = useNavigate();
    let toastRef = useRef();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const { reset, setValue, register, handleSubmit, formState: { errors } } = useForm();
    const [titleList, setTitleList] = useState([]);
    const { state } = useStateMachine();

    const sexList = [
        { 'sex_text': 'Male', 'sex_value': 'M' },
        { 'sex_text': 'Female', 'sex_value': 'F' },
        { 'sex_text': 'Unlisted', 'sex_value': 'U' }
    ]

    const toasts = [
        { title: 'Warning!', content: 'There was a problem saving your details.', cssClass: 'e-toast-danger', icon: 'e-error toast-icons' },
        { title: 'Success!', content: 'Your details have been saved successfully.', cssClass: 'e-toast-success', icon: 'e-success toast-icons' },
    ];

    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const months = [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 }];

    function generateArrayOfYears() {
        var max = new Date().getFullYear();
        var min = max - 120;
        var years = [];

        for (var i = max; i >= min; i--) {
            years.push(i);
        }
        return years;
    }

    const years = generateArrayOfYears();

    useEffect(() => {
        if (!state.login_code)
            navigate(`/`);

        async function fetchData() {
            // load any existing appointments

            fetch(sp + `/details.php?request=${state.login_code}`)
                .then(async response => {

                    const patientResult = await response.json();
                    if (patientResult.error) {
                        alert("Error: " + patientResult.error);
                        return;
                    }

                    if (patientResult.patient) {
                        let patient = patientResult.patient;
                        if (patient.dob) {
                            patient.doby = parseInt(patient.dob.substr(0, 4));
                            patient.dobm = parseInt(patient.dob.substr(4, 2));
                            patient.dobd = parseInt(patient.dob.substr(6, 2));
                        } else {
                            patient.doby = parseInt(new Date().getFullYear());
                            patient.dobm = parseInt(new Date().getMonth());
                            patient.dobd = parseInt(new Date().getDate());
                        }

                        //console.log(patient);

                        reset(patient);
                    }

                    setTitleList(patientResult.titles);
                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });
        }

        fetchData();
    }, [navigate, reset, sp])

    function chooseTitle(event, index) {
        setValue('title', event.itemData.title_text);
        //handleSubmit(onSubmit)();
    }

    function chooseSex(event, index) {
        setValue('sex', event.itemData.sex_value);
        //handleSubmit(onSubmit)();
    }

    function chooseDOBd(event, index) {
        //console.log(event);
        setValue('dobd', event.itemData.value);
        //handleSubmit(onSubmit)();
    }

    function chooseDOBm(event, index) {
        //console.log(event);
        setValue('dobm', event.itemData.value);
        //handleSubmit(onSubmit)();
    }

    function chooseDOBy(event, index) {
        //console.log(event);
        setValue('doby', event.itemData.value);
        //handleSubmit(onSubmit)();
    }

    const onSubmit = async data => {
        const requestOptions = {
            method: 'POST',
            mode: "cors",
            body: JSON.stringify({ login_code: state.login_code, details: data })
        };
        fetch(sp + '/details.php', requestOptions)
            .then(async response => {

                const saveResult = await response.json();
                if (saveResult.result === 'Success')
                    toastRef.current.show(toasts[1]);
                else
                    toastRef.current.show(toasts[0]);

            })
            .catch(error => {
                //setPageError(true);
                //console.error('There was an error!', error);
            });

        //props.finishFnc();
    };

    return (
        <Layout onSubmit={handleSubmit(onSubmit)}>
            <ToastComponent
                ref={toastRef}
                title="Saved!"
                content={"Your details have been updated"}
                position={{ X: "Right", Y: "Top" }}
            />
            {window.innerWidth >= 500 && <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} style={{marginLeft: "10px"}}>
                <Link color="inherit" href={`${process.env.REACT_APP_PATH}/home`}>Home</Link>
                <p className="breadcrumb-highlight">My Details</p>
            </Breadcrumbs>}
            <p style={{margin: "20px"}}>Always check that your details are up to date.</p>
            <form className="my-details-form">
                <Grid container className="form-container">

                    <Grid item xs={12} sm={3}>Title</Grid>
                    <Grid item xs={12} sm={9}>
                        <DropDownListComponent
                            name="title"
                            select={chooseTitle}
                            dataSource={titleList}
                            cssClass="e-outline"
                            {...register("title")}
                            fields={{ text: 'title_text', value: 'title_text' }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>Forenames</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("forenames", { required: true, maxLength: 40, onChange: v => {
                                if (!v.value.length) return v.value;
                                v.target.value = v.value.charAt(0).toUpperCase() + v.value.slice(1);
                              } })}
                        />
                        {errors.forenames && <p className="error">⚠ Forename is required.</p>}
                    </Grid>

                    <Grid item xs={12} sm={3}>Surname</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("surname", { required: true, maxLength: 40, onChange: v => {
                                if (!v.value.length) return v.value;
                                v.target.value = v.value.charAt(0).toUpperCase() + v.value.slice(1);
                              } })}
                        />
                        {errors.surname && <p className="error">⚠ Surname is required.</p>}
                    </Grid>

                    <Grid item xs={12} sm={3}>Date of Birth</Grid>
                    <Grid item xs={12} sm={2}>

                        <DropDownListComponent
                            name="dobd" enabled={false}
                            placeholder="Day"
                            cssClass="e-outline"
                            select={chooseDOBd}
                            dataSource={days}
                            
                            {...register("dobd")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>

                        <DropDownListComponent
                            name="dobm" enabled={false}
                            placeholder="Month"
                            cssClass="e-outline"
                            select={chooseDOBm}
                            dataSource={months}
                           
                            fields={{ text: 'text', value: 'value' }}
                            {...register("dobm")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DropDownListComponent
                            name="doby" enabled={false}
                            cssClass="e-outline"
                            placeholder="Year"
                            select={chooseDOBy}
                            dataSource={years}
                            
                            {...register("doby")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>Sex</Grid>
                    <Grid item xs={12} sm={9}>
                        <DropDownListComponent
                            name="sex" enabled={false}
                            select={chooseSex}
                            cssClass="e-outline"
                            dataSource={sexList}
                            {...register("sex")}
                            fields={{ text: 'sex_text', value: 'sex_value' }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{fontSize:'small'}}>
                        * You cannot change your DOB or sex online, please contact the practice if this is incorrect.
                    </Grid>
                </Grid>
                <hr />
                <Grid container className="form-container">
                    <Grid item xs={12} sm={3}>House Name</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("address1")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>No. &amp; Street</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("address2")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>District</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("address3")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>Town</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("address4")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>County</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("address5")}
                        />
                    </Grid>

                    <Grid item xs={12}  sm={3}>Postcode</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            
                            cssClass="e-outline"
                            {...register("postcode", { maxLength: 10 })}
                        />
                    </Grid>
                </Grid>
                <hr />
                <Grid container className="form-container">
                    <Grid item xs={12} sm={3}>Email Address</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("home_email", {
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                        />
                        {errors.home_email && <p className="error">⚠ Email address is missing, or is not valid.</p>}
                    </Grid>
                </Grid>
                <Grid container className="form-container">
                    <Grid item xs={12} sm={3}>Mobile Number</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextBoxComponent
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("mobile_phone", {
                                required: true
                            })}
                        />
                        {errors.mobile_phone && <p className="error">⚠ Mobile number is missing, or is not valid.</p>}
                    </Grid>
                </Grid>
                <hr />
                 <div className="space-between">        
                <button className="mydetails-btns" type="button" onClick={() => handleSubmit(onSubmit)()}>Save</button>
                <button className="mydetails-btns" type="button" onClick={() => navigate(`/home`)}>Back</button>
                </div>   
            </form>
        </Layout >
    )
}
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BsPersonFill } from "react-icons/bs";
import { BiCalendar, BiCalendarPlus } from "react-icons/bi";
import { RiPhoneFill } from "react-icons/ri";

import Layout from "./layout";
import ContactUsDialog from "./ContactUsDialog";

export default function Dashboard(props) {
    const contactUsDialogRef = useRef();
    let navigate = useNavigate();
    const [configSettings, setconfigSettings] = useState([])

    useEffect(() => {

        async function fetchData() {
            fetch(`${process.env.REACT_APP_LOCAL_SERVER}/config.php?action=setup`)
                .then(async (response) => {
                    const setupResult = await response.json();
                    //console.log(setupResult);

                    if (setupResult.result === "Success") {
                        setconfigSettings(setupResult.settings);
                        //if (setupResult.settings.edit_details === '0')
                        //    setEditDetails(false);
                    }
                })
                .catch((error) => {
                    //setPageError(true);
                    console.error('There was an error!', error);
                });
        }

        fetchData();
    }, []);


    const contactUsDialogOpen = () => {
        contactUsDialogRef.current.show();
    };

    return (
        <Layout>
            <div className="dashboard-container">
                <div className="dashboard-grid">
                    { configSettings.edit_details !== '0' &&
                    <div className="dashboard-button" onClick={() => navigate('/details')}>
                        <BsPersonFill className="dashboard-icon"></BsPersonFill>
                        <p>My Details</p>
                    </div>
                    }
                    <div className="dashboard-button" onClick={() => navigate('/appointments')}>
                        <BiCalendar className="dashboard-icon"></BiCalendar>
                        <p>View Appointments</p>
                    </div>
                    <div className="dashboard-button" onClick={() => navigate('/book')}>
                        <BiCalendarPlus className="dashboard-icon"></BiCalendarPlus>
                        <p>Book Appointment</p>
                    </div>
                    <div className="dashboard-button" onClick={contactUsDialogOpen}>
                        <RiPhoneFill className="dashboard-icon"></RiPhoneFill>
                        <p>Contact Us</p>
                    </div>
                </div>
            </div>
            <ContactUsDialog ref={contactUsDialogRef}></ContactUsDialog>
        </Layout>
    );
}

import { useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import clearAction from "../clearAction";

export default function BookPractice(props) {

    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const [practiceList, setPracticeList] = useState([]);
    const { handleSubmit, setValue, getValues, watch } = useForm();
    const { actions, state } = useStateMachine({ updateAction, clearAction });

    useEffect(() => {
        async function fetchData() {

            //actions.updateAction({ booking: {} });

            fetch(sp + `/config.php?action=practices`)
                .then(async response => {

                    const practiceListResult = await response.json();
                    //practiceListResult.clinicians.push({ displayname: 'Any available clinician', staff_id: '-1' });
                    //console.log(clinicianListResult);
                    setPracticeList(practiceListResult.practices);

                    if (practiceListResult.practices.length === 1) {
                        setValue('practice_id', practiceListResult.practices[0].practice_id);
                        setValue('practice_name', practiceListResult.practices[0].practice_name);
                        handleSubmit(onSubmit)();
                    }

                })
                .catch(error => {
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });

        }

        if (props.tabIndex === 0)
            fetchData();
    }, [props.tabIndex, state, sp])

    function choosePractice(e) {
        //console.log(e);
        //console.log(clinicianList)
        actions.updateAction({ booking: {} });

        setValue('practice_id', e.items[0].practice_id);
        setValue('practice_name', e.items[0].practice_name);
        handleSubmit(onSubmit)();
    }

    const onSubmit = data => {
        //console.log(getValues('clinician'));
        //console.log(data);
        if (getValues('practice_id')) {
            actions.updateAction({ booking: data });
            props.finishFnc(state.booking);
        } else {
            alert('You need to choose a practice to continue');
        }
    };

    const practiceItem = data => {
        //console.log(data);

        return (<div className="settings e-list-wrapper e-list-multi-line">
            <div className="reason-title">{data.practice_name}</div>
            <div>{data.address_display}</div>
            <div>Tel: {data.practice_publicphone}</div>
        </div>);
    }

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            <form>
                <h4 className="booking-text">Which practice would you like to attend?</h4>
                <div className="reason-container">
                    <ListBoxComponent
                        dataSource={practiceList}
                        selectionSettings={{ mode: 'Single' }}
                        change={choosePractice}
                        itemTemplate={practiceItem}
                        cssClass="e-list-template"
                        fields={{ text: 'displayname', value: 'practice_id' }}
                    />
                </div>
                {/*<div className="space-between-mobile">
                    <button className="booking-btns" type="button" onClick={() => handleSubmit(onSubmit)()} >Continue</button>
                    <button className="booking-btns" type="button" onClick={() => goTo(`/home`)}>Cancel</button>
                </div>*/}
            </form>
        </div>
    )
}
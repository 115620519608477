import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { forwardRef } from "react";
import { useStateMachine } from "little-state-machine";

const ContactUsDialog = forwardRef((props, contactUsDialog) => {
  const { state } = useStateMachine();

    const dialogClose = () => {
        contactUsDialog.current.hide();
    }

    //console.log(state)

  return (
    <>
      <DialogComponent
        showCloseIcon={true}
        animationSettings={{ effect: "Zoom" }}
        visible={false}
        width="50%"
        height="40%"
        isModal={true}
        close={dialogClose}
        overlayClick={dialogClose}
        ref={contactUsDialog}
        target={".app-content"}
        header={state.practice.practice_name}>
        <div>
            <hr/>
          <div className="contactus-dialog-fontsize">
            Telephone Number: <br/>
            <a href={"tel:"+state.practice.practice_publicphone}>{state.practice.practice_publicphone}</a>
            <br/>
            <br/>
            Email Address: <br/>
            <a href={"mailto:"+state.practice.practice_email}>{state.practice.practice_email}</a>
          </div>
        </div>
      </DialogComponent>
    </>
  );
});

export default ContactUsDialog;
